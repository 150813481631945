<template>
  <div>
    <HeaderAuth />
    <div
      id="container"
      class="main-container"
    >
      <div
        id="content"
        class="main-content"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderAuth from '@/components/layout/header-auth.vue'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { HeaderAuth },
  data () {
    return {
      showWorkMenu: false
    }
  }
}
</script>
<style scoped>
.main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}

@media (min-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .main-content {
    margin-top: 70px;
  }
}
</style>
