import Vue from 'vue'
import moment from 'moment'
moment.locale('pt-Br')

Vue.filter('formatShortdate', function (value) {
  if (value) {
    return moment(value).format('DD-MM-YYYY')
  } else {
    return null
  }
})
Vue.filter('formatShortdateWithWeek', function (value) {
  if (value) {
    return moment(value).format('DD-MM-YYYY ddd')
  } else {
    return null
  }
})
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm')
  } else {
    return null
  }
})
Vue.filter('formatDateTimeForHumans', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  } else {
    return null
  }
})
Vue.filter('translateInterval', function (value) {
  if (value === 'months') {
    return 'Mensal'
  } else if (value === 'weeks') {
    return 'Semanal'
  } else {
    return null
  }
})
Vue.filter('translateInvoiceStatus', function (value) {
  if (value === 'paid') {
    return 'Paga'
  } else if (value === 'pending') {
    return 'Pendente'
  } else if (value === 'expired') {
    return 'Vencida'
  } else if (value === 'canceled') {
    return 'Cancelada'
  } else {
    return null
  }
})
Vue.filter('formatPrice', function (value) {
  if (value) {
    const val = (value / 100).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } else {
    return '0,00'
  }
})
Vue.filter('formatPriceOriginalDecimal', function (value) {
  if (value) {
    const val = Number(value).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } else {
    return '0,00'
  }
})

Vue.filter('formatPercent', function (value) {
  if (value) {
    const val = Number(value) * 100

    return val.toFixed(2).replace('.', ',')
  } else {
    return '0'
  }
})
Vue.filter('translatePayable', function (value) {
  const options = [
    { id: 'all', name: 'Cartão, Boleto ou Pix' },
    { id: 'credit_card', name: 'Cartão de Crédito' },
    { id: 'bank_slip', name: 'Boleto Bancário' },
    { id: 'pix', name: 'Pix' }
  ]
  const obj = options.find((item) => item.id === value)
  if (obj) {
    return obj.name
  } else {
    return null
  }
})
Vue.filter('translateModel', function (value) {
  if (value.indexOf('Company') !== -1) {
    return 'PJ'
  } else if (value.indexOf('User') !== -1) {
    return 'PF'
  } else {
    return null
  }
})
