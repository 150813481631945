<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/login">
              <img
                src="@/assets/images/logo_arktop_orange.png"
                class="navbar-logo"
                alt="logo"
              >
            </router-link>
          </li>
          <li class="nav-item theme-text">
            <router-link
              to="/login"
              class="nav-link"
            >
              Arktop
            </router-link>
          </li>
        </ul>
      </header>
    </div>
  </div>
</template>
<script>

export default {

  data () {
    return {
      //
    }
  }

}
</script>
<style scoped>

.navbar {
    background: #0e1726;
}
</style>
