<template>
  <div class="widget widget-sales-category">
    <div class="widget-heading">
      <h5>Assinatura</h5>
    </div>
    <div class="widget-content">
      <b-row v-if="company">
        <b-col class="text-center">
          <span v-if="company.subscription">Seu plano é o <strong>{{ company.subscription.plan_name }}</strong></span>
          <span v-else>Você ainda não escolheu um plano de assinatura para sua empresa.</span>
        </b-col>
      </b-row>
      <b-row
        v-if="company"
        class="mt-3"
      >
        <b-col class="text-center">
          <b-button
            v-if="!company.subscription"
            variant="primary"
            :to="{name: 'user-subscription'}"
          >
            Escolher Plano
          </b-button>
          <b-button
            v-else
            variant="primary"
            :to="{name: 'user-subscription'}"
          >
            Planos e Assinatura
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import ApiService from '../../common/api.service'
import { mapState } from 'vuex'

export default {
  name: 'UserSubscription',
  filters: {},
  data () {
    return {

      isLoading: false,
      isLoadingSubscription: false,
      company: null,
      companySubscription: null

    }
  },
  computed: {
    ...mapState({
      channelArktop: (state) => state.chArktop.channelArktop
    })
  },
  watch: {},
  mounted: function () {
    this.getMyAccountInitialData()
  },
  methods: {

    getMyAccountInitialData: async function () {
      this.isLoading = true
      // this.selectedCompanyId = null;
      const userActiveCompany = this.$cookies.get('user_active_company')
      // { id: selectedCompany.id, name: selectedCompany.name }
      await ApiService.get(`users/getUserSubscriptionWidgetData/${userActiveCompany.id}`)
        .then((response) => {
          this.company = response.data.company
          // this.getIuguInvoices(this.company.iugu_client_id)
          if (this.company) {
            if (this.company.subscription) {
              this.getSubscription(this.company.subscription.iugu_id)
            }
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    getSubscription: function (subscriptionId) {
      this.isLoadingSubscription = true
      ApiService.get('subscriptions/getSubscription/' + subscriptionId)
        .then((response) => {
          if (response.status === 200) {
            this.companySubscription = response.data.subscription.subscription
          }
        }).finally(() => {
          this.isLoadingSubscription = false
        })
    }
  }
}
</script>

<style scoped>

</style>
