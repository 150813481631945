<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav
              class="breadcrumb-one"
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  <span>{{ $t("dashboard") }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>
    <b-row class="layout-top-spacing">
      <b-col class="layout-spacing">
        <draggable
          v-model="filteredWidgets"
          class="row"
        >
          <div
            v-for="widget in filteredWidgets"
            :key="widget.id"
            :class="`col-md-${widget.width}`"
            class="mb-4"
          >
            <component :is="widget.component" />
          </div>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import '@/assets/sass/widgets/widgets.scss'
import '@/assets/sass/widgets/widgets-arktop.scss'
import iuguAccounts from '../finance/iugu-accounts.vue'
import onlineUsers from './online-users-widget.vue'
import widgetWorks from './widget-works.vue'
import userSubscription from './user-subscription-widget.vue'

import draggable from 'vuedraggable'

export default {
  name: 'ArktopIndex',
  metaInfo: { title: 'Dashboard' },
  components: {
    draggable,
    iuguAccounts,
    widgetWorks,
    onlineUsers,
    userSubscription
  },
  data () {
    return {
      widgets: [
        { id: 1, component: 'widgetWorks', permission: 'ver-obras', width: 12, order: 1 },
        { id: 2, component: 'iuguAccounts', permission: 'see-arktop-accounts-component', width: 6, order: 2 },
        { id: 3, component: 'onlineUsers', permission: 'ver-usuarios-online', width: 6, order: 3 },
        { id: 4, component: 'userSubscription', permission: 'can-see-subscription-widget', width: 6, order: 1 }
      ]
    }
  },
  computed: {
    filteredWidgets () {
      let filteredWidgets = []
      if (this.$store.state.auth.userpermissions) {
        const userPermissions = this.$store.state.auth.userpermissions // Permissões do usuário
        filteredWidgets = this.widgets
          .filter(widget => userPermissions.includes(widget.permission)) // Verifica se o usuário tem a permissão
          .sort((a, b) => a.order - b.order) // Ordena pela ordem
      }
      return filteredWidgets
    }
  },
  beforeMount () {

  },
  mounted () {},
  methods: {}
}
</script>
<style scoped>
.layout-px-spacing {
  min-height: calc(100vh - 170px) !important;
}

</style>
