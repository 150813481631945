<template>
  <ul
    id="topAccordion"
    class="list-unstyled menu-categories"
  >
    <li class="menu single-menu">
      <router-link
        to="/"
        class="dropdown-toggle autodroprown"
      >
        <div class="">
          <i
            class="fa-light fa-house fa-xl"
            style="overflow: unset; margin-right: 14px"
          />
          <span>{{ $t('dashboard') }}</span>
        </div>
      </router-link>
    </li>

    <li
      v-if="$checkPermission('can-see-registrations-menu')"
      class="menu single-menu"
    >
      <a
        href="javascript:void(0)"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i
            class="fa-light fa-database fa-xl"
            style="overflow: unset; margin-right: 14px"
          />
          <span>{{ $t('registrations') }}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul
        id="datatables"
        class="collapse submenu list-unstyled"
        data-parent="#topAccordion"
      >
        <!-- <router-link tag="li" to="/users/profile"><a>Profile</a></router-link> -->
        <!-- <router-link tag="li" to="/users/account-setting"><a>Account Settings</a></router-link> -->
        <router-link
          tag="li"
          :to="{
            name: 'users',
          }"
        >
          <a>{{ $t('users') }}</a>
        </router-link>
        <router-link
          tag="li"
          :to="{
            name: 'companies',
          }"
        >
          <a>{{ $t('companies') }}</a>
        </router-link>
      </ul>
    </li>
    <li
      v-if="$checkPermission('ver-admin')"
      class="menu single-menu"
    >
      <a
        href="javascript:void(0)"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i
            class="fa-light fa-gear fa-xl"
            style="overflow: unset; margin-right: 14px"
          />
          <span>{{ $t('admin') }}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul
        id="admin"
        class="collapse submenu list-unstyled"
        data-parent="#topAccordion"
      >
        <router-link
          v-if="$checkPermission('list-subscriptions')"
          tag="li"
          :to="{ name: 'subscription-plans' }"
        >
          <a>Planos de Assinatura</a>
        </router-link>
        <router-link
          v-if="$checkPermission('list-subscriptions')"
          tag="li"
          :to="{ name: 'subscriptions' }"
        >
          <a>Assinaturas</a>
        </router-link>
      </ul>
    </li>
    <li
      v-if="$checkPermission('can-see-finance-menu')"
      class="menu single-menu"
    >
      <a
        href="javascript:void(0)"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i
            class="fa-light fa-dollar-sign fa-xl"
            style="overflow: unset; margin-right: 14px"
          />
          <span>{{ $t('finance') }}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul
        id="finance"
        class="collapse submenu list-unstyled"
        data-parent="#topAccordion"
      >
        <router-link
          v-if="$checkPermission('can-see-all-iugu-accounts')"
          tag="li"
          :to="{ name: 'finance-all-iugu-accounts' }"
        >
          <a>Contas Arktop</a>
        </router-link>
        <router-link
          v-if="$checkPermission('pode-ver-transferencias-iugu')"
          tag="li"
          :to="{ name: 'finance-iugu-transfers' }"
        >
          <a>Transferências Iugu</a>
        </router-link>
        <router-link
          v-if="$checkPermission('pode-ver-saques-iugu')"
          tag="li"
          :to="{ name: 'finance-iugu-withdraws' }"
        >
          <a>Saques Iugu</a>
        </router-link>
        <router-link
          v-if="$checkPermission('pode-ver-saques-iugu')"
          tag="li"
          :to="{ name: 'finance-iugu-withdraws-conciliation' }"
        >
          <a>Conciliação de Saques</a>
        </router-link>
        <router-link
          v-if="$checkPermission('see-home-electronic-invoices-component')"
          tag="li"
          :to="{ name: 'finance-electronic-invoices' }"
        >
          <a>Notas Fiscais</a>
        </router-link>
      </ul>
    </li>
    <li
      v-if="$checkPermission('can-see-works-menu')"
      class="menu single-menu"
    >
      <b-link
        :to="{ name: 'works' }"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i
            class="fa-light fa-mountain-city fa-xl"
            style="overflow: unset; margin-right: 14px"
          />
          <span>{{ $t('works') }}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </b-link>
      <ul
        id="works"
        class="collapse submenu list-unstyled"
        data-parent="#topAccordion"
      >
        <router-link
          tag="li"
          :to="{ name: 'works' }"
        >
          <a>Pipeline</a>
        </router-link>
        <li class="sub-sub-submenu-list">
          <a
            href="javascript:;"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
          >
            Por Status
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </a>
          <ul
            id="appInvoice"
            class="collapse list-unstyled sub-submenu"
            data-parent="#app"
          >
            <router-link
              tag="li"
              :to="{ name: 'works-all' }"
            >
              <a>Todas as Obras</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-quotation' }"
            >
              <a>Em Orçamento</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-open' }"
            >
              <a>Em Andamento</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-stopped' }"
            >
              <a>Paradas</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-demo' }"
            >
              <a>Em Demonstração</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-closed' }"
            >
              <a>Finalizadas</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-hibernating' }"
            >
              <a>Em Hibernação</a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'works-lost' }"
            >
              <a>Descartadas</a>
            </router-link>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HeaderMenu',
  filters: {},
  props: {
    workId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userpermissions: (state) => state.auth.userpermissions
    })
  },
  watch: {

  },
  methods: {}
}
</script>

<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped>
</style>
