<template>
  <div class="widget widget-sales-category">
    <div class="widget-heading">
      <h5>Usuários Online</h5>
    </div>
    <div class="widget-content">
      <b-list-group
        flush
        class="chat-container overflow-auto"
      >
        <b-list-group-item
          v-for="(user, index) in channelArktop.activeArktopUsers"
          :key="index"
          style="cursor: pointer"
          @click="$router.push({name: 'edit-user', params:{userId: user.id}})"
        >
          <b-row>
            <b-col
              cols="12"
              sm="12"
            >
              <b-avatar
                variant="info"
                :src="user.avatar"
                class="mr-3"
              />
              <span class="mr-auto">{{ user.name }}</span>
            </b-col>
            <!-- <b-col
              cols="12"
              sm="6"
              class="text-right"
            >
              <span v-if="user.companies.length == 0"> - </span>
              <span
                v-for="(company, indexComp) in user.companies"
                :key="indexComp"
              >
                {{ company.name }}<br>
              </span>
            </b-col> -->
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  filters: {},
  data () {
    return {

      isLoading: false

    }
  },
  computed: {
    ...mapState({
      channelArktop: (state) => state.chArktop.channelArktop
    })
  },
  watch: {},
  mounted: function () {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
