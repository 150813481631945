import Home from '../views/home/index.vue'

const routes = [
  // dashboard
  { path: '/', name: 'Home', component: Home },

  // auth
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/login.vue'),
    meta: { layout: 'auth' }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/register.vue'),
    meta: { layout: 'auth' }
  },
  {
    path: '/register-invited/:token',
    name: 'register',
    component: () => import(/* webpackChunkName: "register-invited" */ '../views/auth/register-invited.vue'),
    props: (route) => {
      return {
        ...route.params,
        ...{ token: route.params.token }
      }
    },
    meta: { layout: 'auth' }
  },
  {
    path: '/auth/pass-recovery',
    name: 'pass-recovery',
    component: () => import(/* webpackChunkName: "pass-recovery" */ '../views/auth/pass_recovery.vue'),
    meta: { layout: 'auth' }
  },
  {
    path: '/auth/reset-password/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/auth/reset-password.vue'),
    props: (route) => {
      return {
        ...route.params,
        ...{ token: route.params.token }
      }
    },
    meta: { layout: 'auth' }
  },
  {
    path: '/company-selector',
    name: 'company-selector',
    component: () => import(/* webpackChunkName: "company-selector" */ '../views/auth/company-selector.vue'),
    meta: { layout: 'auth' }
  },
  // users
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users-users" */ '../views/users/index.vue'),
    meta: { section_name: 'users' },
    props: true
  },
  {
    path: '/users/new',
    name: 'new-user',
    component: () => import(/* webpackChunkName: "new-user" */ '../views/users/new-user.vue'),
    meta: { section_name: 'users' },
    props: true
  },
  {
    path: '/users/:userId/edit',
    name: 'edit-user',
    component: () => import(/* webpackChunkName: "edit-user" */ '../views/users/create-user.vue'),
    meta: { section_name: 'users' },
    props: (route) => {
      return { ...route.params, ...{ userId: Number.parseInt(route.params.userId, 10) } }
    }
  },
  {
    path: '/users/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/users/profile.vue')
  },
  {
    path: '/users/account-setting',
    name: 'account-setting',
    component: () => import(/* webpackChunkName: "account-setting" */ '../views/users/account_setting.vue')
  },
  // companies
  {
    path: '/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "users-users" */ '../views/companies/index.vue'),
    meta: { section_name: 'companies' },
    props: true
  },
  {
    path: '/companies/new',
    name: 'new-company',
    component: () => import(/* webpackChunkName: "new-user" */ '../views/companies/new-company.vue'),
    meta: { section_name: 'companies' },
    props: true
  },

  {
    path: '/companies/:companyId/:activeTab',
    name: 'edit-company',
    component: () => import(/* webpackChunkName: "edit-company" */ '../views/companies/create-company.vue'),
    meta: { section_name: 'companies' },
    props: (route) => {
      return {
        ...route.params,
        ...{
          companyId: Number.parseInt(route.params.companyId, 10),
          activeTab: route.params.activeTab
        }
      }
    }
  },
  // Finance
  {
    path: '/finance/account-statements',
    name: 'finance-account-statements',
    component: () =>
      import(/* webpackChunkName: "finance-account-statements" */ '../views/finance/account-statements.vue'),
    props: true
  },
  {
    path: '/finance/all-iugu-accounts',
    name: 'finance-all-iugu-accounts',
    component: () =>
      import(/* webpackChunkName: "finance-all-iugu-accounts" */ '../views/finance/all-iugu-accounts.vue'),
    props: true
  },
  {
    path: '/finance/iugu-transfers',
    name: 'finance-iugu-transfers',
    component: () => import(/* webpackChunkName: "finance-iugu-transfers" */ '../views/finance/iugu-transfers.vue'),
    props: true
  },
  {
    path: '/finance/iugu-withdraws',
    name: 'finance-iugu-withdraws',
    component: () => import(/* webpackChunkName: "finance-iugu-withdraws" */ '../views/finance/iugu-withdraws.vue'),
    props: true
  },
  {
    path: '/finance/iugu-withdraws-conciliation',
    name: 'finance-iugu-withdraws-conciliation',
    component: () =>
      import(
        /* webpackChunkName: "finance-iugu-withdraws-conciliation" */ '../views/finance/iugu-withdraws-conciliation.vue'
      ),
    props: true
  },
  {
    path: '/finance/finance-electronic-invoices',
    name: 'finance-electronic-invoices',
    component: () =>
      import(
        /* webpackChunkName: "finance-electronic-invoices" */ '../views/finance/electronic-invoices.vue'
      ),
    props: true
  },
  // Works
  {
    path: '/works',
    name: 'works',
    component: () => import(/* webpackChunkName: "works" */ '../views/works/index.vue'),
    meta: { section_name: 'works' }
  },
  {
    path: '/works/all',
    name: 'works-all',
    component: () => import(/* webpackChunkName: "works-all" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 0, workStatusName: 'Todas' } }
    }
  },
  {
    path: '/works/quotation',
    name: 'works-quotation',
    component: () => import(/* webpackChunkName: "works-quotation" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 1, workStatusName: 'Em Orçamento' } }
    }
  },
  {
    path: '/works/open',
    name: 'works-open',
    component: () => import(/* webpackChunkName: "works-open" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 2, workStatusName: 'Em Andamento' } }
    }

  },
  {
    path: '/works/lost',
    name: 'works-lost',
    component: () => import(/* webpackChunkName: "works-lost" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 3, workStatusName: 'Perdidas' } }
    }
  },
  {
    path: '/works/stopped',
    name: 'works-stopped',
    component: () => import(/* webpackChunkName: "works-stopped" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 4, workStatusName: 'Paradas' } }
    }
  },
  {
    path: '/works/closed',
    name: 'works-closed',
    component: () => import(/* webpackChunkName: "works-closed" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 5, workStatusName: 'Finalizadas' } }
    }
  },
  {
    path: '/works/demo',
    name: 'works-demo',
    component: () => import(/* webpackChunkName: "works-demo" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 6, workStatusName: 'Em Demonstração' } }
    }
  },
  {
    path: '/works/hibernating',
    name: 'works-hibernating',
    component: () => import(/* webpackChunkName: "works-hibernating" */ '../views/works/works.vue'),
    meta: { section_name: 'works' },
    props: (route) => {
      return { ...route.params, ...{ workStatusId: 7, workStatusName: 'Em Hibernação' } }
    }
  },
  {
    path: '/works/new',
    name: 'works-new',
    component: () => import(/* webpackChunkName: "works-new" */ '../views/work/create-work.vue'),
    meta: { section_name: 'works' }
  },
  {
    path: '/work/:workId',
    name: 'work',
    component: () => import(/* webpackChunkName: "work" */ '../views/work/index.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/informations',
    name: 'work-informations',
    component: () => import(/* webpackChunkName: "work-informations" */ '../views/work/informations/informations.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/workhours',
    name: 'work-hours',
    component: () => import(/* webpackChunkName: "work-informations" */ '../views/work/schedule/hours.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/team',
    name: 'work-team',
    component: () => import(/* webpackChunkName: "work-team" */ '../views/work/informations/team.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/projects',
    name: 'work-projects',
    component: () => import(/* webpackChunkName: "work-projects" */ '../views/work/informations/projects.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/documents',
    name: 'work-documents',
    component: () => import(/* webpackChunkName: "work-documents" */ '../views/work/informations/documents.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/images',
    name: 'work-images',
    component: () => import(/* webpackChunkName: "work-images" */ '../views/work/informations/images.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/hirings',
    name: 'work-hirings',
    component: () => import(/* webpackChunkName: "work-hirings" */ '../views/work/hirings/index.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/measurements',
    name: 'work-measurements',
    component: () => import(/* webpackChunkName: "work-measurements" */ '../views/work/measurements/index.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/invoices',
    name: 'work-invoices',
    component: () => import(/* webpackChunkName: "work-invoices" */ '../views/work/finance/invoices.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/invoices/new',
    name: 'work-invoices-new',
    component: () => import(/* webpackChunkName: "work-invoices-new" */ '../views/work/finance/new-invoice.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/invoices/:invoiceId',
    name: 'work-invoices-view',
    component: () => import(/* webpackChunkName: "work-invoices-view" */ '../views/work/finance/view-invoice.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), invoiceId: Number.parseInt(route.params.invoiceId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/cash-flow',
    name: 'work-cash-flow',
    component: () => import(/* webpackChunkName: "work-cash-flow" */ '../views/finance/cash-flow.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/electronic-invoices',
    name: 'work-electronic-invoices',
    component: () => import(/* webpackChunkName: "work-electronic-invoices" */ '../views/work/finance/electronic-invoices.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/funds',
    name: 'work-funds',
    component: () => import(/* webpackChunkName: "work-funds" */ '../views/work/funds/funds.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/budget',
    name: 'work-budget',
    component: () => import(/* webpackChunkName: "work-budget" */ '../views/work/budget/budget.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/budget/measurement',
    name: 'work-budget-measurement',
    component: () => import(/* webpackChunkName: "work-budget-measurement" */ '../views/work/budget/budget-measurement.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/budget/buying',
    name: 'work-budget-buying',
    component: () => import(/* webpackChunkName: "work-budget-buying" */ '../views/work/budget/budget-buying.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope',
    name: 'work-scope-index',
    component: () => import(/* webpackChunkName: "work-scope-index" */ '../views/work/scope/index.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope/:quoteId/single',
    name: 'work-scope-single',
    component: () => import(/* webpackChunkName: "work-scope-single" */ '../views/work/scope/scope-single.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), quoteId: Number.parseInt(route.params.quoteId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope/combined',
    name: 'work-scope-combined',
    component: () => import(/* webpackChunkName: "work-scope-combined" */ '../views/work/scope/scope-combined.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope/:quoteId/datails',
    name: 'work-scope-datails',
    component: () => import(/* webpackChunkName: "work-scope-datails" */ '../views/work/scope/scope-details.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), quoteId: Number.parseInt(route.params.quoteId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope/:quoteId/edit',
    name: 'work-scope-edit',
    component: () => import(/* webpackChunkName: "work-scope-edit" */ '../views/work/scope/scope-edit.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), quoteId: Number.parseInt(route.params.quoteId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/scope/:quoteId/pdf',
    name: 'work-scope-pdf',
    component: () => import(/* webpackChunkName: "work-scope-pdf" */ '../views/work/scope/scope-pdf.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), quoteId: Number.parseInt(route.params.quoteId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/schedule',
    name: 'work-schedule',
    component: () => import(/* webpackChunkName: "work-schedule" */ '../views/work/schedule/schedule.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/diary',
    name: 'work-diary',
    component: () => import(/* webpackChunkName: "work-diary" */ '../views/work/diary.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/chat',
    name: 'work-chat',
    component: () => import(/* webpackChunkName: "work-chat" */ '../views/work/chat.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },

  // apps
  {
    path: '/apps/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "apps-chat" */ '../views/apps/chat.vue'),
    props: true
  }
]
export default routes
